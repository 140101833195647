import { auth } from "../../firebase/utils";
import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setBusinesses,
  setBusiness,
  fetchBusinessesStart,
  updateBusinessSuccess,
  fetchBusinessStart,
} from "./businesses.actions";
import {
  handleAddBusiness,
  handleFetchBusinesses,
  handleFetchBusiness,
  handleDeleteBusiness,
  handleUpdateBusiness,
} from "./businesses.helpers";
import businessesTypes from "./businesses.types";

export function* addBusiness({ payload }) {
  try {
    const timestamp = new Date();
    yield handleAddBusiness({
      ...payload,
      userID: auth.currentUser.uid,
      userEmail: auth.currentUser.email,
      claimerID: "",
      createdDate: timestamp,
    });
    yield put(fetchBusinessesStart());
  } catch (err) {}
}

export function* onAddBusinessStart() {
  yield takeLatest(businessesTypes.ADD_NEW_BUSINESS_START, addBusiness);
}

export function* fetchBusinesses({ payload }) {
  try {
    const businesses = yield handleFetchBusinesses(payload);
    yield put(setBusinesses(businesses));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessesStart() {
  yield takeLatest(businessesTypes.FETCH_BUSINESSES_START, fetchBusinesses);
}

export function* fetchBusiness({ payload }) {
  try {
    const business = yield handleFetchBusiness(payload);
    yield put(setBusiness(business));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessStart() {
  yield takeLatest(businessesTypes.FETCH_BUSINESS_START, fetchBusiness);
}

export function* onDeleteBusinessStart() {
  yield takeLatest(businessesTypes.DELETE_BUSINESS_START, deleteBusiness);
}

export function* deleteBusiness({ payload }) {
  try {
    yield handleDeleteBusiness(payload);
    yield put(fetchBusinessesStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* updateBusiness({ payload }) {
  try {
    const updatedBusiness = yield handleUpdateBusiness(payload);
    console.log("Updated business:", updatedBusiness);
    yield put(updateBusinessSuccess(updatedBusiness));
  } catch (err) {
    console.error("Update business saga error:", err);
  }
}

export function* onUpdateBusinessStart() {
  yield takeLatest(businessesTypes.UPDATE_BUSINESS_START, updateBusiness);
}

export default function* businessesSagas() {
  yield all([
    call(onAddBusinessStart),
    call(onFetchBusinessesStart),
    call(onDeleteBusinessStart),
    call(onFetchBusinessStart),
    call(onUpdateBusinessStart),
  ]);
}
