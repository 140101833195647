import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../components/formElements/Buttons";

export default function BusinessNotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "404 - " + t("Place not found");
  }, []);

  return (
    <div className="page">
      <img
        src="./assets/icons/emptyBusinessState.webp"
        alt="Listeat"
        className="headerImage"
      />
      <h1>{t("Place not found")}</h1>
      <p>
        {t(
          "It might be that the place it doens't exist or the owner set it to private"
        )}
        .
      </p>
      <div className="homepageButtons">
        <ButtonPrimary
          title={t("View other places")}
          onClick={() => navigate("/places")}
        />
      </div>
    </div>
  );
}
