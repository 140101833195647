import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  setBusinessOrders,
  setBusinessOrder,
  fetchBusinessOrdersStart,
  updateBusinessOrderSuccess,
} from "./businessOrders.actions";
import {
  handleAddBusinessOrder,
  handleFetchBusinessOrders,
  handleFetchBusinessOrder,
  handleDeleteBusinessOrder,
  handleUpdateBusinessOrder,
} from "./businessOrders.helpers";
import businessOrdersTypes from "./businessOrders.types";

export function* addBusinessOrder({ payload }) {
  try {
    const { businessOrderID, ...businessOrderData } = payload;
    const timestamp = new Date();
    yield handleAddBusinessOrder(
      {
        ...businessOrderData,
        createdDate: timestamp,
      },
      businessOrderID
    );

    yield put(fetchBusinessOrdersStart());
  } catch (err) {
    console.error("Error adding business order:", err);
  }
}

export function* onAddBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.ADD_NEW_BUSINESS_ORDER_START,
    addBusinessOrder
  );
}

export function* fetchBusinessOrders({ payload }) {
  try {
    const businessOrders = yield handleFetchBusinessOrders(payload);
    yield put(setBusinessOrders(businessOrders));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessOrdersStart() {
  yield takeLatest(
    businessOrdersTypes.FETCH_BUSINESS_ORDERS_START,
    fetchBusinessOrders
  );
}

export function* fetchBusinessOrder({ payload }) {
  try {
    const businessOrder = yield handleFetchBusinessOrder(payload);
    yield put(setBusinessOrder(businessOrder));
  } catch (err) {
    // console.log(err);
  }
}

export function* onFetchBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.FETCH_BUSINESS_ORDER_START,
    fetchBusinessOrder
  );
}

export function* onDeleteBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.DELETE_BUSINESS_ORDER_START,
    deleteBusinessOrder
  );
}

export function* deleteBusinessOrder({ payload }) {
  try {
    yield handleDeleteBusinessOrder(payload);
    yield put(fetchBusinessOrdersStart());
  } catch (err) {
    // console.log(err);
  }
}

export function* updateBusinessOrder({ payload }) {
  try {
    const updatedBusinessOrder = yield handleUpdateBusinessOrder(payload);
    yield put(updateBusinessOrderSuccess(updatedBusinessOrder));
  } catch (err) {
    console.error("Update business saga error:", err);
  }
}

export function* onUpdateBusinessOrderStart() {
  yield takeLatest(
    businessOrdersTypes.UPDATE_BUSINESS_ORDER_START,
    updateBusinessOrder
  );
}

export default function* businessOrdersSagas() {
  yield all([
    call(onAddBusinessOrderStart),
    call(onFetchBusinessOrdersStart),
    call(onDeleteBusinessOrderStart),
    call(onFetchBusinessOrderStart),
    call(onUpdateBusinessOrderStart),
  ]);
}
