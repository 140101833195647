import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Tour.scss";
import Joyride from "react-joyride";

function Tour() {
  const { t } = useTranslation();
  const location = useLocation();

  // TOUR
  const [run, setRun] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [joyrideSteps, setJoyrideSteps] = useState([]);

  const getJoyrideSteps = (pathname) => {
    switch (pathname) {
      case "/dashboard":
        return [
          {
            title: t("Add your first post"),
            target: "#addButton",
            content: t(
              "From here you can add new recipes, menus or ingredients"
            ),
            placement: "bottom",
            disableBeacon: true,
          },
        ];
      case "/menu-planner":
        return [
          {
            title: t("Add first recipe"),
            target: "#addRecipeButton",
            content: t(
              "Add your first recipe and we calcultate your nutritional data instantly"
            ),
            placement: "bottom",
            disableBeacon: true,
            disableScrolling: true,
          },
        ];
      case "/shopping-list":
        return [
          {
            title: t("Add custom items"),
            target: "#addCustomItem",
            content: t(
              "You can add custom items such as detergent or any other non food items also."
            ),
            placement: "top",
            disableBeacon: true,
            disableScrolling: true,
          },
        ];
      default:
        return [];
    }
  };

  const customStyles = {
    buttonNext: {
      background: "#FF6900",
      borderRadius: "50px",
    },
    buttonBack: {
      color: "#FF6900",
    },
    tooltipContainer: {
      textAlign: "left",
    },
    tooltipFooter: {
      marginTop: "0",
    },
    beacon: {
      inner: "#000",
      outer: "#000",
    },
  };

  useEffect(() => {
    const storedStepKey = `tourStep_${location.pathname}`;
    const storedStep = Math.max(
      parseInt(localStorage.getItem(storedStepKey), 10) || 0
    );
    const tourCompleted = localStorage.getItem(
      `tourCompleted_${location.pathname}`
    );

    if (!tourCompleted) {
      setRun(true);
      setTourStep(storedStep);
    } else {
      setRun(false);
    }

    const steps = getJoyrideSteps(location.pathname);
    setJoyrideSteps(steps);
  }, [location.pathname, t]);

  const handleJoyrideStepChange = (data) => {
    const { index, action, type } = data;
    if (type === "step:after") {
      const stepToStore = index === 0 ? 2 : index;
      localStorage.setItem(`tourStep_${location.pathname}`, stepToStore);
    }
    if (type === "step:after" && action === "close") {
      localStorage.setItem(`tourCompleted_${location.pathname}`, "true");
      setRun(false);
    }
    if (type === "step:after" && action === "next") {
      const nextStepIndex = index + 1;
      if (nextStepIndex < joyrideSteps.length) {
        setTourStep(nextStepIndex);
      } else {
        localStorage.setItem(`tourCompleted_${location.pathname}`, "true");
        localStorage.removeItem(`tourStep_${location.pathname}`);
        setRun(false);
      }
    }
    if (type === "step:after" && action === "back") {
      const prevStepIndex = Math.max(index - 1, 0);
      if (prevStepIndex >= 0) {
        setTourStep(prevStepIndex);
      }
    }
  };

  return (
    <>
      <Joyride
        steps={joyrideSteps}
        run={run}
        continuous
        controlled
        scrollToFirstStep
        stepIndex={tourStep}
        // showProgress
        callback={handleJoyrideStepChange}
        autoStart={true}
        disableScrollParentFix={true}
        spotlightPadding={10}
        showProgress={false}
        showSkipButton={false}
        styles={{
          ...customStyles,
          buttonNext: {
            display: "none",
          },
          tooltipFooter: {
            display: "none",
          },
        }}
      />
    </>
  );
}

export default Tour;
