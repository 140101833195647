import { firestore } from "../../firebase/utils";

export const handleAddBusiness = (business) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businesses")
      .doc()
      .set(business)
      .then((businessID) => {
        resolve(businessID);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchBusinesses = ({
  filterType,
  startAfterDoc,
  persistBusinesses = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 9;

    let ref = firestore
      .collection("businesses")
      .orderBy("createdDate", "desc")
      .limit(pageSize);

    if (filterType) ref = ref.where("category", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistBusinesses,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 9,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteBusiness = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("businesses")
      .doc(documentID)
      .delete()
      .then(() => {
        // console.log(documentID, "Deleted");
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleFetchBusiness = (businessID) => {
  return new Promise((resolve, reject) => {
    const businessRef = firestore.collection("businesses").doc(businessID);

    businessRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          throw new Error("Document does not exist");
        }

        const businessData = doc.data();
        const newViewsCount = businessData.isViewed
          ? businessData.isViewed + 1
          : 1;

        resolve({
          ...businessData,
          documentID: businessID,
        });

        return businessRef.update({ isViewed: newViewsCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// export const handleFetchBusiness = (businessID) => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection("businesses")
//       .doc(businessID)
//       .get()
//       .then((snapshot) => {
//         if (snapshot.exists) {
//           resolve({
//             ...snapshot.data(),
//             documentID: businessID,
//           });
//         }
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export function searchBusiness() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("search");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");

  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[0];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}

// export const handleUpdateBusiness = (business) => {
//   const { documentID, ...updatedBusiness } = business;
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection("businesses")
//       .doc(documentID)
//       .update(updatedBusiness)
//       .then(() => {
//         resolve({ ...business });
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const handleUpdateBusiness = (business) => {
  const { documentID, ...updatedBusiness } = business;

  const nonEmptyFields = Object.fromEntries(
    Object.entries(updatedBusiness).filter(([_, value]) => value !== "")
  );

  console.log(
    "Updating document with ID:",
    documentID,
    "with data:",
    nonEmptyFields
  );

  return new Promise((resolve, reject) => {
    firestore
      .collection("businesses")
      .doc(documentID)
      .update(nonEmptyFields)
      .then(() => {
        resolve({ ...business });
      })
      .catch((err) => {
        console.error("Update error:", err);
        reject(err);
      });
  });
};

