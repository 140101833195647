import listsTypes from "./lists.types";
import listTypes from "./lists.types";

const INITIAL_STATE = {
  lists: [],
  myList: {},
  error: null,
};

const listsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case listTypes.SET_LISTS:
      return {
        ...state,
        lists: action.payload,
      };
    case listsTypes.SET_LIST:
      return {
        ...state,
        myList: action.payload,
      };
    case listsTypes.UPDATE_LIST_SUCCESS:
      return {
        ...state,
        lists: state.lists.map((myList) =>
          myList.documentID === action.payload.documentID
            ? action.payload
            : myList
        ),
      };

    default:
      return state;
  }
};

export default listsReducer;
