import React, { forwardRef, useState } from "react";
import {
  HiOutlineExclamationCircle,
  HiOutlineEye,
  HiOutlineEyeOff,
  HiOutlineSearch,
} from "react-icons/hi";
import "./formElements.scss";
import { useTranslation } from "react-i18next";

export function InputText({
  id,
  filled,
  handleChange,
  label,
  name,
  required,
  error,
  autoFocus,
  autoComplete,
  ...otherProps
}) {
  return (
    <div
      className={`input${filled ? " filled" : ""}${
        required ? " required" : ""
      }${error ? " error" : ""}`}
    >
      <input
        autoFocus={autoFocus}
        type="text"
        id={id}
        name={name}
        autoComplete={autoComplete}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
      {error ? (
        <small className="textRed marginVertical">
          {error}
          <br />
          <br />
        </small>
      ) : (
        ""
      )}
    </div>
  );
}
export function InputTextIcon({
  id,
  handleChange,
  label,
  icon,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <span className="inputTextIcon">{icon}</span>
      <input
        type="text"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export const InputFile = forwardRef(
  (
    {
      id,
      handleChange,
      label,
      name,
      hiddenInput,
      noFile,
      onClick,
      ...otherProps
    },
    ref
  ) => {
    return (
      <div className="input">
        <input
          type="file"
          id={id}
          ref={ref}
          name={name}
          className={!hiddenInput ? "inputText" : "inputText hidden"}
          onChange={handleChange}
          onClick={onClick}
          {...otherProps}
        />
        {!noFile ? (
          <>
            {label && (
              <label
                htmlFor={id}
                className={`inputLabel ${hiddenInput ? "labelImage" : ""}`}
              >
                {label}
              </label>
            )}
          </>
        ) : (
          <>
            {label && (
              <label
                onClick={onClick}
                className={`inputLabel ${hiddenInput ? "labelImage" : ""}`}
              >
                {label}
              </label>
            )}
          </>
        )}
      </div>
    );
  }
);
export function InputPassword({
  id,
  handleChange,
  label,
  name,
  filled,
  required,
  error,
  ...otherProps
}) {
  const [visible, setVisibility] = useState(false);
  const password = visible ? "text" : "password";

  return (
    <>
      <div
        className={`input password${filled ? " filled" : ""}${
          required ? " required" : ""
        }${error ? " error" : ""}`}
      >
        <input
          type={password}
          id={id}
          name={name}
          className="inputText"
          onChange={handleChange}
          {...otherProps}
        />
        {visible ? (
          <HiOutlineEye
            color="#FF6900"
            onClick={() => setVisibility((visibility) => !visibility)}
          />
        ) : (
          <HiOutlineEyeOff
            onClick={() => setVisibility((visibility) => !visibility)}
          />
        )}
        {label && (
          <label htmlFor={id} className="inputLabel">
            {label}
          </label>
        )}
      </div>
      {error ? (
        <small className="textRed marginVertical">
          {error}
          <br />
          <br />
        </small>
      ) : (
        ""
      )}
    </>
  );
}
export function InputPasswordHidden({
  id,
  handleChange,
  label,
  name,
  filled,
  required,
  error,
  ...otherProps
}) {
  return (
    <>
      <div
        className={`input password${filled ? " filled" : ""}${
          required ? " required" : ""
        }${error ? " error" : ""}`}
      >
        <input
          type="password"
          id={id}
          name={name}
          className="inputText"
          onChange={handleChange}
          {...otherProps}
        />
        {label && (
          <label htmlFor={id} className="inputLabel">
            {label}
          </label>
        )}
        {error ? (
          <small className="textRed marginVertical">
            {error}
            <br />
            <br />
          </small>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
export function InputSearch({
  id,
  handleChange,
  label,
  name,
  hasScanner,
  hasFilter,
  ...otherProps
}) {
  return (
    <div className={`inputSearch ${hasFilter} ${hasScanner} `}>
      {label && (
        <label htmlFor="id" className="inputLabel">
          {label}
        </label>
      )}
      <HiOutlineSearch />
      <input
        type="search"
        id={id}
        name={name}
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
}
export function InputEmail({ id, handleChange, label, name, ...otherProps }) {
  return (
    <div className="input">
      <input
        type="email"
        id={id}
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputPhone({
  id,
  handleChange,
  handleChangePrefix,
  label,
  name,
  prefix,
  ...otherProps
}) {
  const { t } = useTranslation();

  return (
    <div className="input phone">
      <InputSelect
        id="phonePrefix"
        name="Phone prefix"
        label="Prefix"
        required
        value={prefix}
        handleChange={handleChangePrefix}
        options={[
          {
            value: "",
            name: "",
          },
          {
            value: "RO +40",
            name: "RO +40",
          },
          {
            value: "UK +44",
            name: "UK +44",
          },
        ]}
      />
      <InputText
        type="number"
        id="phoneNumber"
        name={name}
        className="inputText"
        handleChange={handleChange}
        {...otherProps}
        required
        minLength="10"
        maxLength="10"
        pattern="\d{10}"
        label={t("Phone number")}
      />
    </div>
  );
}
export function InputDate({
  id,
  handleChange,
  label,
  name,
  required,
  filled,
  error,
  ...otherProps
}) {
  return (
    <div
      className={`input${filled ? " filled" : ""}${
        required ? " required" : ""
      }${error ? " error" : ""}`}
    >
      <input
        id={id}
        type="date"
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputTime({
  id,
  handleChange,
  label,
  name,
  required,
  filled,
  error,
  ...otherProps
}) {
  return (
    <div
      className={`input${filled ? " filled" : ""}${
        required ? " required" : ""
      }${error ? " error" : ""}`}
    >
      <input
        id={id}
        type="time"
        name={name}
        className="inputText"
        onChange={handleChange}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputColor({ handleChange, label, name, ...otherProps }) {
  return (
    <>
      {label && <label>{label}</label>}
      <div className="inputColor">
        <input
          type="color"
          name={name}
          required
          onChange={handleChange}
          {...otherProps}
        />
      </div>
    </>
  );
}
export function InputTextarea({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <textarea id={id} onChange={handleChange} name={name} {...otherProps} />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputTextareaRequired({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <div className="input">
      <textarea
        id={id}
        onChange={handleChange}
        name={name}
        {...otherProps}
        required
      />
      <HiOutlineExclamationCircle className="inputRequiredIcon" />
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputCheckbox({
  id,
  handleChange,
  label,
  name,
  checked,
  ...otherProps
}) {
  return (
    <div>
      <input
        type="checkbox"
        className={`inputCheckbox${checked ? " checked" : ""}`}
        onChange={handleChange}
        id={id}
        name={name}
        checked={checked}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id}>
          <span>
            <p>
              <small>{label}</small>
            </p>
          </span>
        </label>
      )}
    </div>
  );
}
export function InputCheckboxRound({
  id,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  return (
    <>
      <input
        type="checkbox"
        className="inputCheckbox"
        onChange={handleChange}
        id={id}
        name={name}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id}>
          <span>
            <p>
              <small>{label}</small>
            </p>
          </span>
        </label>
      )}
    </>
  );
}
export function InputRange({ id, handleChange, label, name, ...otherProps }) {
  return (
    <>
      {label && <label>{label}</label>}

      <input
        type="range"
        name={name}
        id={id}
        className="inputRange"
        onChange={handleChange}
        {...otherProps}
      />
    </>
  );
}
export function InputRadio({
  id,
  handleChange,
  label,
  name,
  defaultChecked,
  ...otherProps
}) {
  return (
    <>
      <input
        type="radio"
        id={id}
        name={name}
        className="inputRange"
        onChange={handleChange}
        defaultChecked={defaultChecked}
        {...otherProps}
      />
      {label && (
        <label htmlFor={id}>
          <span>
            <p>
              <small>{label}</small>
            </p>
          </span>
        </label>
      )}
    </>
  );
}
export function InputSelect({
  id,
  options,
  value,
  handleChange,
  label,
  name,
  required,
  filled,
  error,
  ...otherProps
}) {
  if (!Array.isArray(options) || options.length < 1) return null;

  return (
    <div
      className={`input${filled ? " filled" : ""}${
        required ? " required" : ""
      }${error ? " error" : ""}`}
    >
      <select
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
        {...otherProps}
      >
        {options.map((option, index) => {
          const { value, name } = option;
          return (
            <option id={id} key={index} value={value} disabled={!value}>
              {name}
            </option>
          );
        })}
      </select>
      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export function InputSelectRequired({
  id,
  options,
  value,
  handleChange,
  label,
  name,
  ...otherProps
}) {
  if (!Array.isArray(options) || options.length < 1) return null;

  return (
    <div className="input">
      <select
        id={id}
        value={value}
        name={name}
        onChange={handleChange}
        {...otherProps}
        required
      >
        {options.map((option, index) => {
          const { value, name } = option;

          return (
            <option id={id} key={index} value={value}>
              {name}
            </option>
          );
        })}
      </select>
      <HiOutlineExclamationCircle className="inputRequiredIcon" />

      {label && (
        <label htmlFor={id} className="inputLabel">
          {label}
        </label>
      )}
    </div>
  );
}
export const CustomCheckbox = ({ checked, onChange }) => {
  return (
    <label className="customCheckbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={!onChange}
      />
      <span className={`checkbox ${checked ? "checked" : ""}`}></span>
    </label>
  );
};
