const businessesTypes = {
  ADD_NEW_BUSINESS_START: "ADD_NEW_BUSINESS_START",
  FETCH_BUSINESSES_START: "FETCH_BUSINESSES_START",
  SET_BUSINESSES: "SET_BUSINESSES",
  DELETE_BUSINESS_START: "DELETE_BUSINESS_START",
  FETCH_BUSINESS_START: "FETCH_BUSINESS_START",
  SET_BUSINESS: "SET_BUSINESS",

  UPDATE_BUSINESS_START: "UPDATE_BUSINESS_START",
  UPDATE_BUSINESS_SUCCESS: "UPDATE_BUSINESS_SUCCESS",
  UPDATE_BUSINESS_ERROR: "UPDATE_BUSINESS_ERROR",
};

export default businessesTypes;
