import userTypes from "./user.types";

export const emailVerificationStart = (userCredentials) => ({
  type: userTypes.EMAIL_VERIFICATION_START,
  payload: userCredentials,
});

export const verifyEmailStart = (oobCode) => ({
  type: userTypes.VERIFY_EMAIL_START,
  payload: oobCode,
});

export const emailVerificationSuccess = () => ({
  type: userTypes.EMAIL_VERIFICATION_SUCCESS,
});

export const emailVerificationError = (error) => ({
  type: userTypes.EMAIL_VERIFICATION_ERROR,
  payload: error,
});

export const emailSignInStart = (userCredentials) => ({
  type: userTypes.SIGN_IN_START,
  payload: userCredentials,
});

export const signInSuccess = (user) => ({
  type: userTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInError = (error) => ({
  type: userTypes.SIGN_IN_ERROR,
  payload: error,
});

export const setLoading = (loading) => ({
  type: userTypes.SET_LOADING,
  payload: loading,
});

export const checkUserSession = () => ({
  type: userTypes.CHECK_USER_SESSION,
});

export const signOutUserStart = () => ({
  type: userTypes.SIGN_OUT_USER_START,
});

export const signOutUserSuccess = () => ({
  type: userTypes.SIGN_OUT_USER_SUCCESS,
});

export const signUpUserStart = (userCredentials) => ({
  type: userTypes.SIGN_UP_USER_START,
  payload: userCredentials,
});

export const signUpUserSuccess = ({ user, additionalData }) => ({
  type: userTypes.SIGN_UP_USER_SUCCESS,
  payload: { user, additionalData },
});

export const signUpUserError = (error) => ({
  type: userTypes.SIGN_UP_USER_ERROR,
  payload: error,
});
export const userError = (error) => ({
  type: userTypes.USER_ERROR,
  payload: error,
});
export const resetPasswordStart = (userCredentials) => ({
  type: userTypes.RESET_PASSWORD_START,
  payload: userCredentials,
});
export const resetPasswordSuccess = () => ({
  type: userTypes.RESET_PASSWORD_SUCCESS,
  payload: true,
});
export const resetPasswordError = (err) => ({
  type: userTypes.RESET_PASSWORD_ERROR,
  payload: err,
});

export const resetUserState = () => ({
  type: userTypes.RESET_USER_STATE,
});

export const googleSignInStart = (navigate) => ({
  type: userTypes.GOOGLE_SIGN_IN_START,
  payload: navigate,
});

//UPDATE USER PROFILE
export const updateUserProfileStart = (user) => ({
  type: userTypes.UPDATE_USER_PROFILE_START,
  payload: user,
});
//UPDATE BLOCK ACTION
export const updateBlockListStart = (userID, targetID) => ({
  type: userTypes.UPDATE_BLOCK_LIST_START,
  payload: { userID, targetID },
});
//SAVE AND REMOVE POST
export const savePostStart = (postID) => ({
  type: userTypes.SAVE_POST_START,
  payload: { postID },
});
export const savePostSuccess = (post) => ({
  type: userTypes.SAVE_POST_SUCCESS,
  payload: post,
});
export const savePostError = (error) => ({
  type: userTypes.SAVE_POST_ERROR,
  payload: error,
});
export const removePostStart = (postID) => ({
  type: userTypes.REMOVE_POST_START,
  payload: postID,
});
export const removePostSuccess = (postID) => ({
  type: userTypes.REMOVE_POST_SUCCESS,
  payload: postID,
});
export const removePostError = (error) => ({
  type: userTypes.REMOVE_POST_ERROR,
  payload: error,
});
//JOYRIDE TOUR
export const joyrideTourStart = () => ({
  type: userTypes.JOYRIDE_TOUR_START,
});
//UPDATE SUBRIPTION PRICE
export const updateSubscriptionPriceStart = (userID, subscriptionPrice) => ({
  type: userTypes.UPDATE_SUBSCRIPTION_PRICE_START,
  payload: { userID, subscriptionPrice },
});

export const updateSubscriptionPriceSuccess = (subscriptionPrice) => ({
  type: userTypes.UPDATE_SUBSCRIPTION_PRICE_SUCCESS,
  payload: subscriptionPrice,
});

export const updateSubscriptionPriceError = (error) => ({
  type: userTypes.UPDATE_SUBSCRIPTION_PRICE_ERROR,
  payload: error,
});
