import React from "react";
import { Nav, NavPage } from "./navigation/Index";
import { WithAuth } from "../hooks/hoc";

export function PrimaryLayout({ children, withAuth = false, index }) {
  const content = withAuth ? <WithAuth>{children}</WithAuth> : children;

  return (
    <React.Fragment key={index}>
      <Nav />
      {content}
    </React.Fragment>
  );
}

export function SecondaryLayout(props, index) {
  return (
    <React.Fragment key={index}>
      <NavPage
        title={props.header}
        transparent={props.transparent}
        hideBackButton={props.hideBackButton}
        homeBackButton={props.homeBackButton}
      />
      {props.children}
    </React.Fragment>
  );
}
