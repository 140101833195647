import React from "react";
import Routing from "./Routing";
import OfflineNotification from "./components/OfflineNotification";

function App() {

  return (
    <>
      <OfflineNotification />
      <Routing />
    </>
  );
}

export default App;
